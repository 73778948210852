import React from "react";
import {Routes, Route} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/Login/index.jsx";
import ForgotPassword from "./components/ForgotPassword/index.jsx";
import ForgotUser from "./components/ForgotUser";
import ResetPassword from "./components/ResetPassword";
import EmailSentMessage from "./components/EmailSentMessage";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ResentEmail from "./components/ResentEmail";
import VerifyEmail from "./components/Verify-Email/verify-email";
import Register from "./components/Register";

const App = () => {
    return (
        <React.Fragment>
            <video autoPlay muted loop id="myVideo" style={{
                position: "fixed",
                right: "0",
                bottom: "0",
                minWidth: "100%",
                minHeight: "100%",
                transform: "translateX(calc((100% - 100vw) / 2))"
            }} onContextMenu={(e) => {
                e.preventDefault()
            }}>
                <source src={"assets/videos/background-video2.m4v"} type="video/mp4"/>
            </video>
            <div style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "linear-gradient(to right, rgba(118, 174, 183, 0.4), rgba(0, 0, 255, 0) 15%)",
                zIndex: 0,
            }}>
            </div>
            <div className="container">
                <Routes>
                    <Route path="/" element={<Login/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/forgot-password" element={<ForgotPassword/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/forgot-username" element={<ForgotUser/>}/>
                    <Route path="/reset-password" element={<ResetPassword/>}/>
                    <Route path="/email-sent" element={<EmailSentMessage/>}/>
                    <Route path="/resent-email" element={<ResentEmail/>}/>
                    <Route path="/verify-email" element={<VerifyEmail/>}/>
                </Routes>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
            />
        </React.Fragment>
    )
}

export default App;
